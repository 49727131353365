import React from "react";
import { UncontrolledCarousel } from 'reactstrap';

const items = [
	{ src: '/images/carousel/01.jpg', caption:''},
	{ src: '/images/carousel/02.jpg', caption:''},
	{ src: '/images/carousel/03.jpg', caption:''},
	{ src: '/images/carousel/04.jpg', caption:''},
	{ src: '/images/carousel/05.jpg', caption:''},
	{ src: '/images/carousel/06.jpg', caption:''},
	{ src: '/images/carousel/07.jpg', caption:''},
	{ src: '/images/carousel/08.jpg', caption:''},
	{ src: '/images/carousel/09.jpg', caption:''},
	{ src: '/images/carousel/10.jpg', caption:''},
	{ src: '/images/carousel/11.jpg', caption:''},
	{ src: '/images/carousel/12.jpg', caption:''},
]

const ImageCarousel = props => {
	return (
  	<React.Fragment>
  		<UncontrolledCarousel items={items} className='carousel mt-5'/>
      
    		<style jsx='true'>
	    	{`
	    		.carousel img {
	    			max-height: 50vh;
	    			width:100vw;
	    			object-fit: cover;
	    		}
	    	
    		`}</style>
    	</React.Fragment>
    )
}

export default ImageCarousel