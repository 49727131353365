import React from "react";
import Hero from "../components/hero"
import Intro from "../components/intro"
import Carousel from "../components/carousel"
import ThingsToDo from "../components/info/Things-to-do"
import EatingAndDrinking from "../components/info/Eating-and-drinking"
//import CallToAction from "../components/cta"
import Faq from "../components/faq"


const Home = props => {	
	return (
    	<React.Fragment>
    		<Hero />
    		<hr style={{borderTop: '1px solid #fff'}}/>
    		<Intro/>
    		<Carousel/>
    		<EatingAndDrinking/>
    		<ThingsToDo/>
    		{//<CallToAction/>
			}
    		<Faq/>
    	</React.Fragment>
    )
}

export default Home