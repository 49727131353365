import React from "react";
import AirBnBLink from '../components/AirBnbLink'

class Bookings extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}

	render(){

		return (
            <>
            <div className="container">
                <hr/>
                <h1 className='display-4 green'>Reservations</h1>
                <hr/>
                <p>You can make a booking online via Airbnb.  For enquires about our packages please send us an email or call.</p>
                
                <AirBnBLink siteName='kereru' room='22197834' />
                <AirBnBLink siteName='piwakawaka' room='53382622' />
                <AirBnBLink siteName='tui' room='53329780' />
                
            </div>
            <style jsx='true'>
                {`
                .green {
                    color: #316220;
                }
                `}
            </style>
            </>
		
		)
	}
}

export default Bookings
