import React from "react";
import { withRouter } from 'react-router-dom'
import url from '../apiurl'
import axios from 'axios'
import moment from 'moment-timezone'
import DayPicker from 'react-day-picker'
import Terms from './bookings-partial/terms'
import 'react-day-picker/lib/style.css'

const addons = [
    { displayName: '2x Mountain Bike or Comfort Bike Hire for the Hauraki Rail Trail +$90', id: 'bikehire' },
    { displayName: '2x E-Bike Hire for the Hauraki Rail Trail +$190', id: 'ebikehire' },
    { displayName: 'Grazing Platter for two +$50', id: 'grazing'},
    { displayName: 'Bouquet of flowers +$60', id: 'flowers'},
    { displayName: 'Bottle of Bubbly +$40', id: 'bobbly'}
]

class Grabone extends React.Component {

	constructor(props) {
		super(props)
		this.state={ 
            start: moment.tz('2021-12-13', 'Pacific/Auckland'),
            end: moment.tz('2021-12-14', 'Pacific/Auckland'),
            disabledDays: [0,1,2,3,4,5,6],
            deal: '',
            loading: false,
            error: false,
		}
	}

    check = ev => {
        ev.preventDefault()
        //const start = this.convertDate(this.state.checkin)
        //const end = start.clone().add(this.state.lengthOfStay, 'days')

        const start = moment(this.state.checkin).format('YYYYMMDD')
        const end = moment(start, 'YYYYMMDD').add(this.state.lengthOfStay, 'days').format('YYYYMMDD')

        this.setState({available: null, loading: true})
        axios.get(url + 'getBookings?start=' + start + '&end=' + end)
            .then(res => {
                let available = 0
                Object.keys(res.data).forEach( k => {
                    if (res.data[k]) available ++
                })
                
                this.setState({available, loading: false})
            }).catch (err => {
                this.setState({loading: false})
                //console.log(err)
            })
    }
/*
    check = ev => {
        ev.preventDefault()
        const start = this.convertDate(this.state.checkin)
        const end = start.clone().add(this.state.lengthOfStay, 'days')

        this.setState({available: null, loading: true})
        axios.get(url + 'getBookings?start=' + encodeURIComponent(start.format()) + '&end=' + encodeURIComponent(end.format()))
            .then(res => {
                console.log(res.data)
                let available = 0
                Object.keys(res.data).forEach( k => {
                    if (res.data[k]) available ++
                })
                
                this.setState({available, loading: false})
            }).catch (err => {
                this.setState({loading: false})
                //console.log(err)
            })
    }
*/
    convertDate = d => {
        let dd = d.getDate()
        let mm = d.getMonth() +1
        let yy = d.getFullYear()

        if (dd < 10) dd = '0' + dd
        if (mm < 10) mm = '0' + mm

        return moment.tz(yy + '-' + mm + '-' + dd, 'Pacific/Auckland')
    }

    selectDeal = ev => {
        this.setState({deal: ev.target.value, checkin: undefined, available: null})
        if (ev.target.value === '1dayweek') this.setState({disabledDays: [5,6], lengthOfStay: 1, deal_: '1 Night, Weekdays'})
        else if (ev.target.value === '2dayweek') this.setState({disabledDays: [4,5,6], lengthOfStay: 2, deal_: '2 Nights, Weekdays'})
        else if (ev.target.value === '2daywkend') this.setState({disabledDays: [], lengthOfStay: 2, deal_: '2 Nights, Weekends'})
        else this.setState({disabledDays:[0,1,2,3,4,5,6]})

    }

    handleDayClick = (d, disabled) => {
        if (!disabled.disabled) {
            this.setState({checkin: d, available: null})
        }
    }

	componentDidMount() {
		window.scrollTo(0, 0)
	}

    submit = (ev) => {
        ev.preventDefault()
        this.setState({loading: true, error: false})
        
        let r = {}
        const start = this.convertDate(this.state.checkin)
        const end = start.clone().add(this.state.lengthOfStay, 'days')
        r.checkin = start.format('ddd Do MMM YYYY')
        r.checkout = end.format('ddd Do MMM YYYY')
        r.deal = this.state.deal_
        
        const data = new FormData(ev.currentTarget)
        for (let [key, value] of data.entries()) { r[key] = value }

        axios.post(url + 'newBooking', r)
			.then(response => {
				this.props.history.push('/success')
			})
			.catch(err => {
                this.setState({loading:false, error:true})
			})
    }

	render(){
        const { available=null, deal, checkin, disabledDays, lengthOfStay, loading, error } = this.state

		return (
			<React.Fragment>
				<div className='container'>

                    {/*  START CALENDAR PICKER  */}
                    {available <1 && <>
                    <div>
                        <h5 className='text-primary'>GrabOne Voucher is for..</h5>
                        <select onChange={this.selectDeal} value={deal}>
                            <option>Select an option</option>
                            <option value='1dayweek'>Weekdays 1 Night</option>
                            <option value='2dayweek'>Weekdays 2 Nights</option>
                            <option value='2daywkend'>Weekend 2 Nights</option>
                        </select>
                    </div>
    
                    <hr/>
                    { !!deal.length && (deal !== 'Select an option') &&
                    <div>
                        <h5 className='text-primary'>Check in Date</h5>

                        <DayPicker
                            locale='en'
                            selectedDays={[checkin]}
                            onDayClick={this.handleDayClick}
                            disabledDays= {[{daysOfWeek: disabledDays}, {before: new Date(), after: new Date('30 april 2024')}]}
                        />
                    </div>}
                    
                    
                    {checkin && !!checkin.getDate() && 
                    <div>
                        <b>Check In:</b> {moment(checkin).format('ddd Do MMM YYYY')}<br/>
                        <b>Check Out:</b> {moment(checkin).add(lengthOfStay, 'days').format('ddd Do MMM YYYY')}<br/>
                        { available === 0 && <div className="mt-3 alert alert-danger" role="alert">Sorry, there is no availability on these dates.  Please try a different date.</div>}                                
                        <button className='mt-3 btn btn-success' onClick={this.check} disabled={loading}>{loading ? 'Please Wait..' : 'Check Availability'}</button>
                    </div>}

                    </> /*  END CALENDAR PICKER  */}


                    {available > 0 && <>
                    <button className='btn btn-link' onClick={()=>this.setState({available: null})}>&#129168; Choose a different date</button>
                    <div className="mt-3 alert alert-success" role="alert">Great! Your selected dates are available.</div>    			
                    <h5 className='my-3 text-primary'> Reservation for {moment(checkin).format('ddd Do MMM YYYY')} - {moment(checkin).add(lengthOfStay, 'days').format('ddd Do MMM YYYY')}</h5>
                    <form className='noselect' onSubmit={this.submit}>
                        <div className='form-group'>
                            <label htmlFor='customerName'>Guest Names</label>
                            <input required className='form-control' name='Name' id='customerName' placeholder='Name'/>
                        </div>
                        <div className='form-group'>
                            <label htmlFor='customerEmail'>Email Address</label>
                            <input type='email' required className='form-control' name='Email' id='customerEmail' placeholder='Email Address'/>
                        </div>
                        <div className='form-group'>
                            <label htmlFor='customerPhone'>Phone Number</label>
                            <input required className='form-control' name='Phone' id='customerPhone' placeholder='Phone Number'/>
                        </div>
                        <div className='form-group'>
                            <label htmlFor='customerg1'>Grab One Voucher Number</label>
                            <input required className='form-control' name='Voucher #' id='customerg1' placeholder='Grab One Voucher #'/>
                            <small id="voucherhelp" className="form-text text-muted">This is your coupon number from the top right corner of your voucher.  NOT the purchase ID.</small>
                        </div>

                        <div className="form-group">
                            <label htmlFor="notes">Comments or special requests</label>
                            <textarea className="form-control" name='Comments' id="notes" rows="3"></textarea>
                        </div>

                        <p><b>Please select any extra's you wish to add to your reservation.</b></p>
                        {addons.map( i => 
                            <div className="form-check" key={i.id}>
                                <input className="form-check-input" name={i.id} type="checkbox" value="selected" id={i.id}/>
                                <label className="form-check-label" htmlFor={i.id}>{i.displayName}</label>
                            </div>
                        )}

                        <Terms/>

                        <div className="form-check">
                            <input className="form-check-input" name='Terms & Conditions' type="checkbox" value="accepted" id='terms' required/>
                            <label className="form-check-label" htmlFor='terms'>I agree to the terms and conditions</label>
                        </div>
                        { error && <div className="mt-3 alert alert-danger" role="alert">Whoops :(   An error occured.  Please contact us by email or phone to complete this reservation if this error persists.</div>}
                        <button className='mt-5 btn btn-success' disabled={loading}>Confirm Reservation</button>

                    </form>

                    </>}
                </div>
				<style jsx='true'>
			    	{`
			    		.scroll {
                            max-height: 180px;
                            overflow-y: auto;
                        }
		    		`}
	    		</style>
			</React.Fragment>
		)
	}
}

export default withRouter(Grabone)