import React from "react";
import { FaUtensils } from "react-icons/fa/";
import ScrollableAnchor from 'react-scrollable-anchor'

const EatingAndDrinking = props => {
	return (
  	<React.Fragment>
  		<ScrollableAnchor id={'eating-and-drinking'}>
  			<div className='row'>
	  			<div className='col-12 col-md-6 text-center px-0 order-md-2'>
	  				<div className='p-5 bg-color-1'>
	  					<FaUtensils className='display-3 info-animate'/>
		  				<h2 className='info-title'>Eating And Drinking</h2>
		  				<p>Bliss Retreat is self catering.  Your private glamping kitchen is provisioned with a gas cooker and chilli-bin (Esky/Cooler) with ice packs refreshed daily.  Tea & Coffee is provided as well as cutlery, crockery, pots & pans.</p>
		  				<p>You can pre-order a delicious 'Grazing Platter' ready on your arrival.  Look for this option on the bookings page.</p>
		  				<p>The nearest supermarket is a 5 minute drive away in Waihi and has everything you need to cook up a storm.</p>
		  				<p>If you want a break from cooking, there is a great selection of cafes and restaurants in nearby Waihi and Waihi Beach.</p>
		  			</div>
	  			</div>
	  			<div className='col-12 col-md-6 px-0 order-md-1 bg-image-1'>
	  			</div>
	  		</div>
  		</ScrollableAnchor>

   		<style jsx='true'>
	    	{`
	    		.bg-image-1 {
	    			background: url('/images/info/eat.jpg');
	    			background-size: cover;
	    			background-position: center;
	    			min-height:350px;
	    		}

	    		.info-title {
	    			text-transform: uppercase;
	    			font-weight: 100;
	    		}

	    		.bg-color-1 {
	    			background: #cdc192;
	    			color: #fff;
	    			height:100%;
	    		}

	    		.info-animate {
		        	animation-duration: 8s;
		            animation-name: iconRotate;
		            animation-iteration-count: infinite;
		        }

		        @keyframes iconRotate {
		          0% {
		            transform: rotate(0);
		          }
		          10% {
		            transform: rotate(360deg);
		          }
		          100% {
		            transform: rotate(360deg);
		          }
		        }

    		`}</style>
    	</React.Fragment>
    )
}

export default EatingAndDrinking