import React from "react";
import { Link } from 'react-router-dom'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap'

class Header extends React.Component {

	constructor(props) {
		super(props)
		this.state = { collapsed: true }
	}

	toggleNavbar = () => this.setState({collapsed: !this.state.collapsed})

	render() {
		return (
    	<React.Fragment>
    		<Navbar color='white' light expand='md'>
				<NavbarBrand href='/'><img src='/images/logo.png' alt='Bliss Retreat' className='logo-header'/></NavbarBrand>
    			<NavbarToggler onClick={this.toggleNavbar} />
					<Collapse isOpen={!this.state.collapsed} navbar>
	    			<Nav className='ml-auto' navbar>
							<NavItem><Link to='/' className='nav-link'>Home</Link></NavItem>
							<NavItem><a href="/#eating-and-drinking" className='nav-link'>Eating & Drinking</a></NavItem>
							<NavItem><a href="/#things-to-do" className='nav-link'>Things to do</a></NavItem>
							<NavItem><a href="/#faq" className='nav-link'>Faq</a></NavItem>
							<NavItem><Link to='/packages' className='nav-link'>Packages</Link></NavItem>
							<NavItem><Link to='/bookings' className='nav-link'>Bookings</Link></NavItem>
						</Nav>
					</Collapse>
    		</Navbar>
    	
    		{/* --- STYLES --- */}
      	<style jsx='true'>
      		{`
      		.logo-header {
      			width:120px;
      		}
      		`}</style>
    	</React.Fragment>
    )
	}
}

export default Header