import React from "react";

class Terms extends React.Component {

	render() {
		return (
			<React.Fragment>
                <div className='card mt-5'>
                    <div className='card-body'>
                        <div className='card-title'><h5>Terms & Conditions</h5></div>
                        <div className='card-text scroll'>
                            <b>Reservation Status</b>
                            <p>Your reservation is currently held on a confirmed basis. If you wish to change or cancel the booking, please contact the Bliss Retreat directly.  Please note that we cannot guarantee the quoted rate if any changes are made to your original request.</p>
                            <b>Check In Time</b>
                            <p>3pm is our standard check-in time. Early check in is subject to availability at an additional rate of $20 per hour</p>
                            <b>Check Out Time</b>
                            <p>For the convenience of the incoming guests, we require our sites to be vacated by 10:00 am on the day of departure. Late departure is subject to availability at the rate of $20 per hour</p>
                            <b>Late Arrival</b>
                            <p>For guests arriving after sunset, please contact us.</p>
                            <b>Cancellation Policy</b>
                            <p>When the booking is cancelled within 5 days prior to arrival, first night’s accommodation will be charged.  If the booking is cancelled within 24hrs or a no-show, no refunds will be given.  Full payment or voucher # is required at time of check in.</p>
                            <b>Payment Policy</b>
                            <p>For all reservations, payment must be made in full on the day of check in if not already done so.  There is a $1.00 credit card pre-authorisation per site per night required at time of check in.  The pre-authorisation is released upon check out.</p>
                            <b>Incidentals</b>
                            <p>Any damage to the room or property may be charged to nominated credit card or method of payment for incidentals, plus a charge for any resultant loss of income if applicable.  If you do smoke please do so outside your tent and dispose in the rubbish bin. If any damage then you may be charged appropriate cleaning and loss of income.</p>
                            <b>Damage & Loss</b>
                            <p>Bliss Retreat assumes no responsibility for the loss of money or other valuables or for articles left in tents or vehicles for any reason.</p>
                            <b>On site activities</b>
                            <p>Bliss Retreat and all staff take no responsibility for any accidents that take place on or off our land and are all done so at your own risk, such as but not limited to:</p>
                            <ul>
                                <li>Swimming in the river</li>
                                <li>Horse riding</li>
                                <li>The swing</li>
                                <li>Animals on-site</li>
                                <li>Walking tracks</li>
                                <li>Biking tracks</li>
                                <li>Cooking on gas stoves</li>
                                <li>Bath</li>
                                <li>Candles</li>
                            </ul>
                        </div>
                    </div>

                </div>
			
			</React.Fragment>
		)
	}
}

export default Terms	