import React, { Component } from 'react';
import { BrowserRouter as Router, Route, } from 'react-router-dom';
import Header from './components/header/'
import Footer from './components/footer/'
import Home from './pages/Home'
import Grabone from './pages/Grabone'
import BookingsDivert from './pages/BookingsDivert'
import Packages from './pages/Packages'
import Success from './pages/bookings-partial/Success';
import './main.css'


class App extends Component {
  render() {
    return (
      <Router>
        <div className='Site'>
          <div className='Site-content'>
            <Header />
            <Route exact path={'/'} component={() => <Home />} />
            <Route exact path={'/bookings'} component={() => <BookingsDivert />} />
            <Route exact path={'/packages'} component={() => <Packages />} />
            <Route exact path={'/grabone'} component={() => <Grabone />} />
            <Route exact path={'/success'} component={() => <Success />} />
            
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
