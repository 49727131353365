import React from "react";

const Success = props =>
	<React.Fragment>
		<div className='container'>
			<div className='row'>
				<div className='col-12 col-md-6'>
					<h4 className='text-success'>Success!</h4>
					<div class="mt-3 alert alert-success" role="alert">Your reservation is being processed.  You will receive email confirmation within 24 hours.</div>    			
					<p className='lead'>Thanks for booking at Bliss Retreat.  We look forward to hosting you.</p>
				</div>
				<div className='col-12 col-md-6'>
					<img src='/images/carousel/01.jpg' alt='' style={{width: '100%'}}/>
				</div>
			</div>
		</div>	

		<style jsx='true'>
	    	{`
	    	
    		`}</style>
	</React.Fragment>

export default Success