import React from "react";
import { SlideDown } from 'react-slidedown'
import { FaCaretRight } from "react-icons/fa/";

class Faq extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false }
  }

  toggle = ev => {
    ev.preventDefault()
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { isOpen } = this.state
  	return (
    	<React.Fragment>
        <div>
          <div onClick={this.toggle} className='faq-question-container'> 
            <span className='faq-question'>{this.props.children[0]}</span>
            <FaCaretRight className={ isOpen ? 'faq-caret faq-caret-rotate' : 'faq-caret' } />
          </div>

          <SlideDown className='faq-answer'>
            {isOpen ? this.props.children[1] : null }
          </SlideDown>
          <hr/>
        </div>
      		<style jsx='true'>
  	    	{`
            .faq-question-container:hover {
              color:#cb9801;
              cursor:pointer;
            }

            .faq-question-container {
              color: #316220;
              text-transform: uppercase;
              font-weight:100;
              font-size: 1.3em;

            }
            .faq-caret {
              float:right;
              transition-duration: 200ms;
              transition-property: transform;
            }

            .faq-caret-rotate {
              transform: rotate(90deg)
            }

            .faq-answer {
              color:#999;
              font-weight:100;
              transition-duration: 200ms;
            }
      		`}</style>
      	</React.Fragment>
    )
  }
}

export default Faq