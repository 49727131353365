import React from "react";
import { withRouter } from 'react-router-dom'
import { FaHeart } from "react-icons/fa/";
import { FaGift } from "react-icons/fa/";


class Packages extends React.Component {

	constructor(props) {
		super(props)
		this.state={
			
		}
	}

	componentDidMount() {
		window.scrollTo(0, 0)
	}

	bookingsLink = () => {
		this.props.history.push('/bookings')
	}

	render(){


		return (
			<React.Fragment>
				<div className='container'>
					<h1 className='display-4 green'>Packages</h1>
					<h2 className='lead green mb-4'>Available for 2 and 3 nights stays.</h2>
				</div>
				<div className='row'>
		  			<div className='col-12 col-md-6 px-0 text-center'>
		  				<div className='p-5 bg-color-2'>
		  					<FaHeart className='display-3 info-animate'/>
			  				<h2 className='info-title'>Romantic Get Away</h2>
			  				<p>Enjoy a romantic get away with that someone special at Bliss Retreat.</p>
			  				<ul className='text-left'>
			  					<li>Two nights accomadation in a luxury belle tent</li>
			  					<li>Bottle of wine on arrival</li>
			  					<li>Late check out of 12pm</li>
			  					<li><em>Back To Basics</em> beauty kit for two - includes hand made face mask, body scrub, and body butter. All made with 100% pure oils and locally grown products.</li>
			  					<li>$520 /couple</li>
							</ul>

			  				<p className='mb-0 text-left'><b>How to book</b></p>
			  				<p className='text-left'>
			  					Please email <em>info@blissretreat.co.nz</em>
			  				</p>
						</div>	  				
		  			</div>
	  				<div className='col-12 col-md-6 px-0 bg-image-2'></div>
	  			</div>

	  			<div className='row'>
		  			<div className='col-12 col-md-6 text-center px-0 order-md-2'>
		  				<div className='p-5 bg-color-1'>
		  					<FaGift className='display-3 info-animate'/>
			  				<h2 className='info-title'>Reboot</h2>
			  				<p>Recharge, Relaxation, Romance.</p>
			  				<ul className='text-left'>
			  					<li>Three nights accomadation in a luxury belle tent</li>
			  					<li>Bottle of wine on arrival</li>
								<li>Late check out of 12pm</li>
			  					<li>Grazing Platter for two on arrival</li>
			  					<li><em>Back To Basics</em> beauty kit for two - includes hand made face mask, body scrub, and body butter. All made with 100% pure Oils and locally grown products.</li>
			  					<li>Day hire for 2x mountain bikes including drop-off and pick-up at the Hauraki Rail Trail</li>
								<li>$990 /couple</li>
			  				</ul>

			  				<p className='mb-0 text-left'><b>How to book</b></p>
			  				<p className='text-left'>
							  Please email <em>info@blissretreat.co.nz</em>
			  				</p>
			  			</div>
		  			</div>
		  			<div className='col-12 col-md-6 px-0 order-md-1 bg-image-1'>
	  			</div>
	  		</div>
				

				<style jsx='true'>
			    	{`
			    		.green {
			    			color: #316220;
			    		}
			    		.bg-image-1 {
			    			background: url('/images/carousel/01.jpg');
			    			background-size: cover;
			    			background-position: center;
			    			min-height:350px;
			    		}

			    		.info-title {
			    			text-transform: uppercase;
			    			font-weight: 100;
			    		}

			    		.bg-color-1 {
			    			background: #cdc192;
			    			color: #fff;
			    			height:100%;
			    		}
			    		.bg-image-2 {
			    			background: url('/images/carousel/12.jpg');
			    			background-size: cover;
			    			background-position: center;
			    			min-height: 350px;
			    		}

			    		.info-animate {
				        	animation-duration: 8s;
				            animation-name: iconRotate;
				            animation-iteration-count: infinite;
				        }

				        @keyframes iconRotate {
				          0% {
				            transform: rotate(0);
				          }
				          10% {
				            transform: rotate(360deg);
				          }
				          100% {
				            transform: rotate(360deg);
				          }
				        }

				        .bg-color-2 {
			    			background: #c3ab97;
			    			color: #fff;
			    			height:100%;
			    		}
		    		`}
	    		</style>
			</React.Fragment>
		)
	}
}

export default withRouter(Packages)