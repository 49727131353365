import React from "react";
import { FaCalendarAlt } from "react-icons/fa/";
import ScrollableAnchor from 'react-scrollable-anchor'
import { withRouter } from 'react-router-dom'

const Intro = props => {
	return (
  	<React.Fragment>
  		<ScrollableAnchor id={'bliss-retreat'}>
	  		<section className='container'>
	  			<div className='row'>
	  				<div className='col-12 col-lg-6'>
	  					<h1 className='display-4 green mb-0'>Bliss Retreat</h1>
	  					<h2 className='lead green mb-4'>WAIHI, NEW ZEALAND</h2>
	  					<p>Nestled in the beautiful Waihi area – gateway to Karangahake Gorge and close to some of New Zealand’s best beaches, Bliss Retreat is a luxury glamping getaway set amongst idyllic bush surroundings.</p>
							<p>Come relax in one of our three perfectly designed Bell tents with comfort in mind, enjoy the sound of the river flowing and the birds singing.</p>
							<p>Only a short drive to beautiful swimming and surfing beaches or walking and cycling trails. We can organise bikes or boards for an adventurous day out! Explore old railway tunnels or swim under picturesque waterfalls. This is New Zealand at its best!</p>
							<p>Or stay and enjoy the day at Bliss Retreat.  You can bathe in the river, enjoy the tranquil setting and let all your worries wash away!  All the makings of a wonderful getaway. Only 45 mins from Tauranga and under 2 hours from Auckland. This is a must visit destination!</p>

	  				</div>

	  				<div className='col-12 col-lg-6 text-center pt-5'>
	  					
	  					{/*<div className='row align-items-center justify-content-center green text-center'>
	  						<div className='col-auto text-right px-0'>FROM</div>
	  						<div className='col-auto text-center display-3 px-0'>$150 </div>
	  						<div className='col-auto text-left px-0'> p/n</div>
	  					</div>
					<p className='mt-2'>Adults Only</p>*/}
	  					<button onClick={()=>props.history.push('/bookings/#')} className='styled-button'><FaCalendarAlt className='cal-icon'/>CHECK AVAILABILITY</button>
	  				</div>
	  			</div>
	  			
	  		</section>
	  	</ScrollableAnchor>
		<style jsx='true'>
    	{`
    		.green {
    			color: #316220;
    		}

    		.styled-button {
    			font-weight:100;
    			font-size: 2em;
    			border: 1px solid #316220;
    			border-radius: 5px;
    			width:100%;
    			padding:50px 10px;
    			color:#316220;
    			transition: 1s;
    		}

    		.styled-button:hover {
    			background: #316220;
    			color: #fff;
    		}

    		.cal-icon {
    			padding-right:25px;
    			font-size: 2em;
    		}

		`}</style>
    </React.Fragment>
    )
}

export default withRouter(Intro)