import React from "react";
import { FaQuestionCircle } from "react-icons/fa/";
import FaqItem from './FaqItem'
import ScrollableAnchor from 'react-scrollable-anchor'
import 'react-slidedown/lib/slidedown.css'

const Faq = props => {
	return (
  	<React.Fragment>
      <ScrollableAnchor id={'faq'}>
    		<section className='container'>
          <div>
            
            <h2 className='faq-title py-4'>
            <FaQuestionCircle className='faq-icon'/>
            <br/>
            FAQs</h2>
          </div>

          <hr/>
          
          <FaqItem>
            <span>What time is check-in and check-out?</span>
            <span>Check-in is at 3pm, check-out by 10am or later by arrangement.</span>
          </FaqItem>

          <FaqItem>
            <span>What are the bed and bedding arrangments?</span>
            <span>All linen and towels are provided.</span>
          </FaqItem>

          <FaqItem>
            <span>What are the kitchen and cooking facilities?</span>
            <span>Bliss Retreat has a fully self catering kitchen at each site with everything you need. There is a gas cooker and a chilly bin with ice packs that are changed daily.</span>
          </FaqItem>

          <FaqItem>
            <span>What are the bathroom facilities?</span>
            <span>There is a beautiful rain shower and a romantic outdoor claw foot bath.  The eco friendly composting toilet is changed regularly and works by chucking a handful of wood chips in every time its used.</span>
          </FaqItem>

          <FaqItem>
            <span>Is there Power?</span>
            <span>No mains power! This site is completely off grid which is what makes it so special! There is however a place to charge your phone and lighting in both the tent and your kitchen area, as well as automatic lighting in the bathroom and shower.</span>
          </FaqItem>

          <FaqItem>
            <span>Is there mobile phone reception?</span>
            <span>Yes most phones get reception.</span>
          </FaqItem>

          <FaqItem>
            <span>Is Bliss Retreat child friendly? </span>
            <span>In most cases we are not set up to cater for children, but we have had the occassional Mother and Daughter weekend away so feel free to contact us to discuss.</span>
          </FaqItem>

          <FaqItem>
            <span>How remote is the campsite?</span>
            <span>Each site is a short 2 – 3 min walk from your car but feels like you are in the middle of nowhere! Waihi township with everything you need is about a 5 min drive away.</span>
          </FaqItem>

          <FaqItem>
            <span>Where do I park my car?</span>
            <span>Parking is provided and information sent to you at time of booking.</span>
          </FaqItem>

          <FaqItem>
            <span>What do I need to bring?</span>
            <span>Walking shoes to get to and from your site, food and drink (tea, coffee, milk and hot chocolate provided. Don't forget you can also add a grazing platter on at time of booking.) swimming gear.</span>
          </FaqItem>

          <FaqItem>
            <span>Can I book for one night only?</span>
            <span>Yes from Monday – Thursday you can book a one night stay, but we require a two night booking over the weekend.</span>
          </FaqItem>

          <FaqItem>
            <span>Is there a first aid kit?</span>
            <span>Yes each site has a first aid kit located inside the tent.</span>
          </FaqItem>

          <FaqItem>
            <span>Do we need to clean our tent before we leave? </span>
            <span>We just ask that the washing up is done and the rubbish and recycling are left tidy.</span>
          </FaqItem>

    		</section>
      </ScrollableAnchor>

    		<style jsx='true'>
	    	{`
	    	  .faq-title {
            text-transform: uppercase;
            font-weight:100;
            text-align:center;
          }

          .faq-icon {
            text-align:center;
            font-size: 3em;
            color:#ddd;
            animation-duration: 6s;
            animation-name: iconWriggle;
            animation-iteration-count: infinite;
            }

            @keyframes iconWriggle {
              0% {
                transform: rotate(0);
              }
              5% {
                transform: rotate(30deg);
              }
              10% {
                transform: rotate(-25deg);
              }
              15% {
                transform: rotate(20deg);
              }
              20% {
                transform: rotate(-15deg);
              }
              25% {
                transform: rotate(10deg);
              }
              30% {
                transform: rotate(-5deg);
              }
              35% {
                transform: rotate(2deg);
              }
              40% {
                transform: rotate(0);
              }
              100% {
                transform: rotate(0);
              }
          }

    		`}</style>
    	</React.Fragment>
    )
}

export default Faq