import React from "react";
import { FaHiking } from "react-icons/fa/";
import ScrollableAnchor from 'react-scrollable-anchor'

const ThingsToDo = props => {
	return (
  	<React.Fragment>
  		<ScrollableAnchor id={'things-to-do'}>
			<div className='row'>
	  			<div className='col-12 col-md-6 px-0 text-center'>
	  				<div className='p-5 bg-color-2'>
	  					<FaHiking className='display-3 info-animate-2'/>
		  				<h2 className='info-title'>Things To Do</h2>
		  				<p>There's no chance of running out of things to do at Bliss Retreat!  Enjoy a soak in our beautiful outdoor bath or put on your walking shoes and head down to the river taking in the stunning native NZ scenery and wildlife.  Then, take a refreshing dip in the river or just lay in the hammock with a good book.</p>
		  				<p>As night falls listen out for Morepork (native NZ Owl) or grab a torch and explore the glow-worm walk.  Or just sit and enjoy the stars.</p>
		  				<p>Explore the famous Hauraki Rail Trail on a mountain bike from Adventure Bike Hire (free dropoff & pickup so you don't need a bike rack) or head into town (5 mins) and learn more about Waihi's rich goldmining history.</p>
		  				<p>Visit Waihi Beach (15 mins) or one of the many walking tracks in the area.</p>
					</div>	  				
	  			</div>
	  			<div className='col-12 col-md-6 px-0 bg-image-2'>
	  			</div>
	  		</div>
	  	</ScrollableAnchor>
   		<style jsx='true'>
	    	{`
	    		.bg-image-2 {
	    			background: url('/images/info/do.jpg');
	    			background-size: cover;
	    			background-position: center;
	    			min-height: 350px;
	    		}

	    		.info-animate-2 {
		        	animation-duration: 8s;
		            animation-name: iconMove;
		            animation-iteration-count: infinite;
		        }

		        @keyframes iconMove {
		          0% {
		            transform: translateX(-10px);
		          }
		          50% {
		            transform: translateX(10px);
		          }
		          55% {
		            transform: translateX(-10px);
		          }
		          100% {
		            transform: translateX(-10px);
		          }
		        }

		        .bg-color-2 {
	    			background: #c3ab97;
	    			color: #fff;
	    			height:100%;
	    		}

    		`}</style>
    	</React.Fragment>
    )
}

export default ThingsToDo