import React from "react";

const ImageCarousel = props => {
	return (
        <div className='row mt-4'>
            <div className='col-12 text-center'>
                <a href={'https://www.airbnb.co.nz/rooms/'+props.room} target="_blank" rel="noopener noreferrer">
                    <img className='zoom' src={'/images/airbnb/' + props.siteName + '.png'} alt='' />
                </a>
            </div>
        <style jsx='true'>
        {`
            .zoom {
                width: 100%;
                max-width: 500px;
                transition: transform .2s; /* Animation */
              }
              
              .zoom:hover {
                transform: scale(1.025); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
              }
        
        `}</style>

        </div>

  	
    )
}





export default ImageCarousel